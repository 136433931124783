import { IAuthUser } from './auth-user.model';

export const authUserMock: IAuthUser = {
	amAccountId: 'amAccountIdMock',
	employee: '3',
	family_name: 'familyNameMock',
	given_name: 'givenNameMock',
	name: 'nameMock',
	nickname: 'nicknameMock',
	picture: 'pictureUrlMock',
	role: '2',
	sub: 'subMock',
	updated_at: 'updatedAtMock',
	userId: 'userIdMock',
};
