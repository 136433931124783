import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AdGroupExpansionStateService {
	private readonly storageKey = 'adGroupExpansionState';

	constructor() {}

	public saveState(
		campaignId: string,
		userId: string,
		state: Record<string, boolean>,
	): void {
		const allStates = this.getAllStates();
		allStates[`${campaignId}_${userId}`] = state;
		localStorage.setItem(this.storageKey, JSON.stringify(allStates));
	}

	public getState(
		campaignId: string,
		userId: string,
	): Record<string, boolean> {
		const allStates = this.getAllStates();
		return allStates[`${campaignId}_${userId}`] || {};
	}

	private getAllStates(): Record<string, Record<string, boolean>> {
		return JSON.parse(localStorage.getItem(this.storageKey) || '{}');
	}
}
