import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { IActivityLoggerService } from '../monitoring/new-relic/activity-logger.interface';

interface IAction {
	title: string;
	data?: any;
}

@Injectable({ providedIn: 'root' })
export class ActivityLoggerService implements IActivityLoggerService {
	private readonly logs: IAction[] = [];
	private debounce = false;
	private debounceTimer: any;
	private readonly sentinelService = inject(SentinelService);
	private readonly timeout = 510;
	private readonly recentCommands = new Set<string>();

	public log(command: string, data?: any): void {
		if (this.debounce) {
			clearTimeout(this.debounceTimer);
		}
		this.debounceTimer = setTimeout(() => {
			this.debounce = false;
			this.recentCommands.clear();
		}, this.timeout);

		if (this.debounce && this.recentCommands.has(command)) {
			return;
		}

		this.debounce = true;
		this.recentCommands.add(command);

		const action: IAction = {
			title: command,
			data: data,
		};
		this.logs.push(action);

		this.sentinelService.trackPageAction(action.title, action.data);

		(window as any).activities = this.logs.slice(
			this.logs.length - 10,
			this.logs.length,
		);
	}

	public startTrace(traceName: string, data?: any): void {
		this.sentinelService.startTrace(traceName, data);
	}

	public finishTrace(traceName: string, data?: any): void {
		this.sentinelService.finishTrace(traceName, data);
	}
}
