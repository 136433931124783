import { Injectable, Injector } from '@angular/core';
import { UserEntityService } from './user/user-entity.service';
import { LocalizationsEntityService } from './localization/localizations-entity.service';
import { IntegrationsEntityService } from './integration/integrations-entity.service';
import { IntegrationConnectionStatusEntityService } from './integration-connection-status/integration-connection-status-entity.service';
import { CreativeSetEntityService } from './creative-set/creative-set-entity.service';
import { CreativeSetFolderEntityService } from './creative-set-folder/creative-set-folder-entity.service';
import { NetworksEntityService } from './network/network-entity.service';

@Injectable()
export class AccountEntityServices {
	public creativeSet: CreativeSetEntityService;
	public creativeSetFolder: CreativeSetFolderEntityService;
	public integration: IntegrationsEntityService;
	public integrationConnectionStatus: IntegrationConnectionStatusEntityService;
	public localization: LocalizationsEntityService;
	public network: NetworksEntityService;
	public user: UserEntityService;

	constructor(private injector: Injector) {
		this.creativeSet = this.injector.get(CreativeSetEntityService);
		this.creativeSetFolder = this.injector.get(
			CreativeSetFolderEntityService,
		);
		this.integration = this.injector.get(IntegrationsEntityService);
		this.integrationConnectionStatus = this.injector.get(
			IntegrationConnectionStatusEntityService,
		);
		this.localization = this.injector.get(LocalizationsEntityService);
		this.network = this.injector.get(NetworksEntityService);
		this.user = this.injector.get(UserEntityService);
	}
}
