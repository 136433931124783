import {
	Component,
	Input,
	OnChanges,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { ILinkedInSinglePlacementsMetaDataMap } from 'src/app/presentation/view-models/linkedin/placement/in-placement-metadata.vm';
import { LinkedInSinglePlacementsComponentStore } from './in-single-placements.component.store';
import { AdPlacementPreviewDirective } from 'src/app/presentation/pages/shared/directives/ad-placement-preview.directive';
import {
	IPlacementSoundVM,
	IPlacementsSoundDictionary,
} from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';
import { Observable, Subscription, tap } from 'rxjs';
import { AdFormatEnum } from 'src/app/presentation/view-models';
import { PlacementSoundComponent } from '../../../elements/placement-sound/placement-sound.component';
import { PlacementHeaderComponent } from '../../../elements/placement-header/placement-header.component';
import { LinkedInPlacementsWrapperComponent } from '../placements-wrapper/in-placements-wrapper.component';
import { LinkedInPlacementTitleComponent } from '../placement-title/in-placement-title.component';

@Component({
	selector: 'in-single-placements',
	templateUrl: './in-single-placements.component.html',
	styleUrls: ['./in-single-placements.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		LinkedInPlacementsWrapperComponent,
		LinkedInPlacementTitleComponent,
		AdPlacementPreviewDirective,
		PlacementHeaderComponent,
		PlacementSoundComponent,
	],
	providers: [LinkedInSinglePlacementsComponentStore],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedInSinglePlacementsComponent
	implements OnChanges, OnInit, OnDestroy
{
	@Input() public placementsMetaData: ILinkedInSinglePlacementsMetaDataMap;
	@Input() public inPreview: boolean;
	@Input() public placementsSound$: Observable<IPlacementsSoundDictionary>;

	@Output() public onPlacementSoundChanged: EventEmitter<IPlacementSoundVM> =
		new EventEmitter();

	@Output() public onPlacementsRendered: EventEmitter<number> =
		new EventEmitter();

	public placementsIds: string[];
	public placementsSoundSubscription$: Subscription;
	public adFormatEnum: typeof AdFormatEnum = AdFormatEnum;
	public soundEnum: typeof SoundEnum = SoundEnum;

	constructor(
		private linkedInSinglePlacementsComponentStore: LinkedInSinglePlacementsComponentStore,
	) {}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.onPlacementsRendered.emit(this.placementsIds.length);
		});
	}

	public ngOnChanges(): void {
		this.linkedInSinglePlacementsComponentStore.setPlacementsMetaData(
			this.placementsMetaData,
		);
		this.placementsIds = Object.keys(this.placementsMetaData);
	}

	public ngOnInit(): void {
		if (!this.inPreview && this.placementsSound$) {
			this.placementsSoundSubscription$ = this.placementsSound$
				.pipe(
					tap((placementsSound) =>
						this.linkedInSinglePlacementsComponentStore.setPlacementsSound(
							placementsSound,
						),
					),
				)
				.subscribe();
		}
	}

	public onSoundChange(sound: SoundEnum, placementId: string): void {
		const placementSound: IPlacementSoundVM = {
			id: placementId,
			sound,
		};

		this.onPlacementSoundChanged.emit(placementSound);
	}

	public ngOnDestroy(): void {
		if (this.placementsSoundSubscription$) {
			this.placementsSoundSubscription$.unsubscribe();
		}
	}
}
