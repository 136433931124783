import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	AppState,
	AuthService as Auth0AuthService,
	GenericError,
} from '@auth0/auth0-angular';
import { Logger } from '@bannerflow/sentinel-logger';
import { filter, map, Observable, of } from 'rxjs';
import { authUserMock } from './auth-user.mock';
import { ConfigService } from '../../config/config.service';
import { IAuthUser } from './auth-user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private readonly logger = new Logger(AuthService.name);
	private readonly auth0AuthService = inject(Auth0AuthService);

	constructor() {
		this.logoutOnAuthErrors();
	}

	public getAppState(): Observable<AppState> {
		return this.auth0AuthService.appState$;
	}

	public getAuthUser(): Observable<IAuthUser> {
		if (ConfigService.isCypress()) {
			return of(authUserMock);
		}

		return this.auth0AuthService.user$.pipe(
			filter((user) => user !== null && user !== undefined),
			map((user) => user as IAuthUser),
		);
	}

	public getAccessToken(): Observable<string> {
		if (ConfigService.isCypress()) {
			return of('accessTokenMock');
		}

		return this.auth0AuthService
			.getAccessTokenSilently()
			.pipe(filter((token) => !!token));
	}

	private logoutOnAuthErrors(): void {
		this.auth0AuthService.error$
			.pipe(
				takeUntilDestroyed(),
				filter(
					(e) =>
						e instanceof GenericError &&
						(e.error === 'login_required' ||
							e.error === 'invalid_grant'),
				),
			)
			.subscribe(() => {
				this.logger.warn('Unauthorized, logging out.');
				this.auth0AuthService.logout();
			});
	}
}
