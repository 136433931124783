import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeAll, take } from 'rxjs/operators';
import { IUser } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IUserVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { UserMappersService } from '../../mappers';

export enum BannerflowFeaturesEnum {
	SocialCampaignManager = 'SocialCampaignManager',
	TiktokFeature = 'SocialCampaignManagerNetworks.TikTok',
	LinkedInFeature = 'SocialCampaignManagerNetworks.LinkedIn',
}
@Injectable({
	providedIn: 'root',
})
export class UserBaseService extends BaseFeatureService<IUserVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public userMapper: UserMappersService,
	) {
		super();
	}

	public load(): Observable<IUserVM> {
		const user$: Observable<IUser> =
			this.appEntityServices.accountEntity.user.entities$.pipe(
				mergeAll(),
				take(1),
			);

		return user$.pipe(
			filter((user) => !!user),
			map((user: IUser) => this.userMapper.mapToUserVM(user)),
		);
	}

	public getWithQuery(params: {
		accountSlug: string;
		brandSlug: string;
	}): Observable<IUser[]> {
		return this.appEntityServices.accountEntity.user.getWithQuery({
			accountSlug: params.accountSlug,
			brandSlug: params.brandSlug,
		});
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IUserVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IUserVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IUserVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
