import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import {
	Observable,
	of,
	map,
	switchMap,
	filter,
	tap,
	Subscription,
	combineLatest,
	take,
} from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IFacebookPlacementVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { FacebookSinglePlacementsComponentStore } from './fb-single-placements.component.store';
import { FacebookSupportedPlacements } from './shared/enums';
import {
	AdPreviewDirective,
	IAdPreviewMetaData,
} from 'src/app/presentation/pages/shared/directives/ad-preview.directive';
import { PlacementOptionsComponent } from './shared/facebook-placement-options/placement-options.component';
import { FacebookPlacementTitleComponent } from './shared/facebook-placement-title/facebook-placement-title.component';
import { FacebookPlacementsWrapperComponent } from '../../placements-wrapper/fb-placements-wrapper.component';
import { AdPlacementPreviewDirective } from 'src/app/presentation/pages/shared/directives/ad-placement-preview.directive';
import { AdTargetUrlComponent } from 'src/app/presentation/features/ad/ad-target-url/ad-target-url.component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	IPlacementSoundVM,
	IPlacementsSoundDictionary,
} from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';
import { PlacementHeaderComponent } from '../../../../elements/placement-header/placement-header.component';
import { PlacementSoundComponent } from '../../../../elements/placement-sound/placement-sound.component';
import { LetDirective } from '@ngrx/component';
import { IConnection } from 'src/app/core/models';
import { PromotedObjectTypeEnum } from 'src/app/presentation/view-models/shared/promoted-object-type.enum';
import { IFacebookSinglePlacementsMetaDataMap } from '../../../../../../view-models/facebook/placement/fb-placement-metadata.vm';
import { ActivityLoggerService } from 'src/app/core/utilities/activity-logger.service';

@Component({
	selector: 'fb-single-placements',
	templateUrl: './fb-single-placements.component.html',
	styleUrls: ['./fb-single-placements.component.scss'],
	providers: [FacebookSinglePlacementsComponentStore],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		TextPipe,
		LetDirective,
		AdPreviewDirective,
		AdPlacementPreviewDirective,
		AdTargetUrlComponent,
		FacebookPlacementTitleComponent,
		FacebookPlacementsWrapperComponent,
		PlacementOptionsComponent,
		PlacementHeaderComponent,
		PlacementSoundComponent,
	],
})
export class FacebookSinglePlacementsComponent implements OnInit, OnDestroy {
	@Input() public inPreview: boolean;
	@Input({ required: true })
	public placementsMetaData$: Observable<IFacebookSinglePlacementsMetaDataMap>;
	@Input() public adSetPlacementsIds$: Observable<string[]> = of([]);
	@Input() public isBulk = false;
	@Input() public placementsSound$: Observable<IPlacementsSoundDictionary>;
	@Output() public onPlacementSoundChanged: EventEmitter<IPlacementSoundVM> =
		new EventEmitter();
	@Output() public onPlacementsRendered: EventEmitter<number> =
		new EventEmitter();
	public placements$: Observable<string[]>;

	public hoveredPlacement: string;
	public adPreviewMetaData: IAdPreviewMetaData;
	public facebookSupportedPlacements: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	public isMenuActive: boolean;
	public activePlacement: string;
	public supportedPlacements$: Observable<IFacebookPlacementVM[]>;
	public placementsSoundSubscription$: Subscription;
	public mediaType: typeof MediaTypeEnum = MediaTypeEnum;
	public soundEnum: typeof SoundEnum = SoundEnum;
	public promotedObjectTypeEnum: typeof PromotedObjectTypeEnum =
		PromotedObjectTypeEnum;
	public adGroupConnection$: Observable<IConnection>;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private singlePlacementsStore: FacebookSinglePlacementsComponentStore,
		private readonly activityLoggerService: ActivityLoggerService,
	) {}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.placements$.pipe(take(1)).subscribe((placements) => {
				this.onPlacementsRendered.emit(placements.length);
			});
		});
	}

	public ngOnInit(): void {
		this.singlePlacementsStore.setPlacementsMetaData(
			this.placementsMetaData$,
		);

		this.placements$ = this.singlePlacementsStore.loadPlacementsList();

		const adGroup$ = this.placementsMetaData$.pipe(
			switchMap((placementsMetaData) => {
				const metaDataFirstItemKey = Object.keys(placementsMetaData)[0];

				const adGroupId =
					placementsMetaData[metaDataFirstItemKey]?.adGroupId;

				return this.appFeatureServices.adFeature.adGroup.loadById(
					adGroupId,
				);
			}),
		);

		this.supportedPlacements$ = combineLatest([
			adGroup$,
			this.placementsMetaData$,
		]).pipe(
			switchMap(([adGroup, placementsMetaData]) => {
				const metaDataFirstItemKey = Object.keys(placementsMetaData)[0];

				const adId = placementsMetaData[metaDataFirstItemKey]?.adId;

				return this.appFeatureServices.facebookFeature.placement.filterSupportedPlacementsByAdId(
					adGroup?.supportedPlacementsIds,
					adId,
				);
			}),
		);

		this.adGroupConnection$ = adGroup$.pipe(
			filter((adGroup) => !!adGroup.connection),
			switchMap((adGroup) =>
				this.appFeatureServices.adFeature.adGroupConnection.loadById(
					adGroup.id,
				),
			),
		);

		if (!this.inPreview && this.placementsSound$) {
			this.placementsSoundSubscription$ = this.placementsSound$
				.pipe(
					tap((placementsSound) =>
						this.singlePlacementsStore.setPlacementsSound(
							placementsSound,
						),
					),
				)
				.subscribe();
		}
	}

	public openMenu(value: any): void {
		this.isMenuActive = value.isMenuOpen;

		if (this.isMenuActive) {
			this.activePlacement = value.placementId;
		}

		if (!this.isMenuActive && this.activePlacement === value.placementId) {
			this.activePlacement = null;
		}
	}

	public showTargetUrl(value: string): void {
		this.hoveredPlacement = value;
	}

	public hideTargetUrl(): void {
		this.hoveredPlacement = undefined;
	}

	public isPlacementMatchedCampaignObjective(
		placementId: string,
	): Observable<boolean> {
		return this.adSetPlacementsIds$.pipe(
			map((adSetPlacementsIds: string[]) =>
				adSetPlacementsIds?.length
					? adSetPlacementsIds.includes(placementId)
					: true,
			),
		);
	}

	public onSoundChange(sound: SoundEnum, placementId: string): void {
		const placementSound: IPlacementSoundVM = {
			id: placementId,
			sound,
		};

		this.onPlacementSoundChanged.emit(placementSound);
	}

	public ngOnDestroy(): void {
		if (this.placementsSoundSubscription$) {
			this.placementsSoundSubscription$.unsubscribe();
		}
	}
}
