import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { CreativeSetFolderDataService } from './creative-set-folder/creative-set-folder-data.service';
import { CreativeSetFolderEntityService } from './creative-set-folder/creative-set-folder-entity.service';
import { CreativeSetDataService } from './creative-set/creative-set-data.service';
import { CreativeSetEntityService } from './creative-set/creative-set-entity.service';
import { IntegrationDataService } from './integration/integration-data.service';
import { IntegrationsEntityService } from './integration/integrations-entity.service';
import { IntegrationConnectionStatusDataService } from './integration-connection-status/integration-connection-status-data.service';
import { IntegrationConnectionStatusEntityService } from './integration-connection-status/integration-connection-status-entity.service';
import { LocalizationDataService } from './localization/localization-data.service';
import { LocalizationsEntityService } from './localization/localizations-entity.service';
import { UserDataService } from './user/user-data.service';
import { UserEntityService } from './user/user-entity.service';
import { NetworkDataService } from './network/network-data.service';
import { NetworksEntityService } from './network/network-entity.service';
import { EntityDataService } from '@ngrx/data';
import {
	CREATIVE_SET_ENTITY,
	CREATIVE_SET_FOLDER_ENTITY,
	INTEGRATION_CONNECTION_STATUS_ENTITY,
	INTEGRATION_ENTITY,
	LOCALIZATION_ENTITY,
	NETWORK_ENTITY,
	USER_ENTITY,
} from 'src/app/state/app.state';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		CreativeSetDataService,
		CreativeSetEntityService,
		CreativeSetFolderDataService,
		CreativeSetFolderEntityService,
		IntegrationDataService,
		IntegrationsEntityService,
		IntegrationConnectionStatusDataService,
		IntegrationConnectionStatusEntityService,
		LocalizationDataService,
		LocalizationsEntityService,
		UserDataService,
		UserEntityService,
		NetworkDataService,
		NetworksEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityAccountModule {
	private userDataService: UserDataService;
	private networkDataService: NetworkDataService;
	private localizationDataService: LocalizationDataService;
	private integrationDataService: IntegrationDataService;
	private integrationConnectionStatusDataService: IntegrationConnectionStatusDataService;
	private creativeSetFolderDataService: CreativeSetFolderDataService;
	private creativeSetDataService: CreativeSetDataService;

	constructor(
		entityDataService: EntityDataService,
		private injector: Injector,
	) {
		this.userDataService = this.injector.get(UserDataService);
		this.networkDataService = this.injector.get(NetworkDataService);
		this.localizationDataService = this.injector.get(
			LocalizationDataService,
		);
		this.integrationDataService = this.injector.get(IntegrationDataService);
		this.integrationConnectionStatusDataService = this.injector.get(
			IntegrationConnectionStatusDataService,
		);
		this.creativeSetFolderDataService = this.injector.get(
			CreativeSetFolderDataService,
		);
		this.creativeSetDataService = this.injector.get(CreativeSetDataService);

		entityDataService.registerService(USER_ENTITY, this.userDataService);
		entityDataService.registerService(
			NETWORK_ENTITY,
			this.networkDataService,
		);
		entityDataService.registerService(
			LOCALIZATION_ENTITY,
			this.localizationDataService,
		);
		entityDataService.registerService(
			INTEGRATION_ENTITY,
			this.integrationDataService,
		);
		entityDataService.registerService(
			INTEGRATION_CONNECTION_STATUS_ENTITY,
			this.integrationConnectionStatusDataService,
		);
		entityDataService.registerService(
			CREATIVE_SET_FOLDER_ENTITY,
			this.creativeSetFolderDataService,
		);
		entityDataService.registerService(
			CREATIVE_SET_ENTITY,
			this.creativeSetDataService,
		);
	}
}
